<template>
  <div class="publish" v-loading="loading">
    <el-form ref="form" :model="form" label-width="" :rules="rules">
      <el-form-item prop="heading" class="form_label">
        <el-row>
          <el-col :md="{span:1}">
            <span style="font-size: 20px;"> <strong>标题</strong></span>
          </el-col>
          <el-col :md="{span:23}">
            <input v-model="form.heading" style="width: 100%;border: none;outline: none;padding-left: 10px;"
              placeholder="请输入标题"></input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="form_label">
        <el-row>
          <el-col :md="{span:2}">
            <span style="font-size: 20px;"> <strong>代码块配色</strong></span>
          </el-col>
          <el-col :md="{span:22}">
            <el-select collapse-tags v-model="form.codeStyle" placeholder="请选择代码块配色" style="width:100%;">
              <div class="el-input" style="width:100%;">
                <input v-model="codeStyleValue" placeholder="请输入" class="el-input__inner"
                  style="border: none;outline: none;" @keyup="codeStyleSearch" />
              </div>
              <el-option style="display: none;" key="none" value="none" label="暂无数据">
              </el-option>

              <el-option v-for="(codeStyleItem,index) in codeStyleList" :key="codeStyleItem.style"
                :label="codeStyleItem.style" :value="codeStyleItem.style"></el-option>
            </el-select>
          </el-col>
        </el-row>

      </el-form-item>
      <el-form-item prop="blog">
        <!-- <span slot="label">
          <span class="data_label">文章内容</span>
        </span> -->
        <mavon-editor ref="mavonEditor" @imgAdd="handleEditorImgAdd" @imgDel="imgDel" :toolbars="toolbars"
          :scrollStyle="true" :ishljs="true" @change="change" v-model="blogContent" :codeStyle="form.codeStyle"
          :externalLink="externalLink"
          style="max-height:740px;"
           />
      </el-form-item>
      <el-form-item>
        <el-upload
          class="upload-demo"
          drag
          action="none"
          :auto-upload="false"
          :on-change="(file,fileList)=>uploadFile(file,fileList,'fileUploadLoading')"
          v-loading="loadingObj['fileUploadLoading'] || parseInt(fileUploadProgress)>0"
          multiple
          :show-file-list="false"
          >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            <ul style="margin-top: 0px;list-style: none;padding: 0px;">
              <li style="height: 20px;"
              v-for="fileItem in cacheFileList"
              >{{fileItem.fileName}}  {{fileItem.filePath}} <span style="cursor: pointer;color: #409EFF;"
              @click="copy('['+fileItem.fileName+']('+fileItem.filePath+')')"
              > 复制</span></li>
            </ul>
          </div>
        </el-upload>
        <!-- <el-progress
        v-if="loadingObj['fileUploadLoading']"
        :percentage="parseInt(fileUploadProgress)"
        :color="progressColors"
        style="width: 500px;"
        ></el-progress> -->
       <el-progress
       v-if="loadingObj['fileUploadLoading']||parseInt(fileUploadProgress)>0"
        :text-inside="true"
        :color="progressColors"
        style="width: 500px;" :stroke-width="26" :percentage="parseInt(fileUploadProgress)"
        ></el-progress>
      </el-form-item>
      <el-form-item prop="synopsis" class="form_label">
        <el-row>
          <el-col :md="{span:1}">
            <span style="font-size: 20px;"> <strong>描述</strong></span>
          </el-col>
          <el-col :md="{span:23}" class="data_synopsis">
            <el-input v-model="form.synopsis" @input="synopsisChange()" maxlength="500" type="textarea" show-word-limit
              style="width: 100%;border: none;outline: none;padding-left: 10px;" placeholder="描述,在封皮可以简单介绍这篇文章的内容">
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item prop="backgroundImg" class="form_label">
        <el-row>
          <el-col :md="{span:24}">
            <span style="font-size: 20px;"> <strong>上传封面图片</strong></span>
          </el-col>
          <el-col :md="{span:24}">
            <div class="cropper-app">
              <el-form :model="formValidate" :rules="ruleValidate" ref="formValidate" label-width="100px"
                class="demo-ruleForm">
                <el-form-item prop="mainImage">
                  <div class="list-img-box">
                    <div v-if="formValidate.mainImage !== ''">
                      <el-image :src="formValidate.mainImage" style="width: 360px;" fit="cover"
                        :preview-src-list="preImgUrlList" @click="previewCurrentImg(form.backgroundImg)">
                      </el-image>
                    </div>
                    <div v-else class="upload-btn" style="height: 120px;" @click="uploadPicture('flagImg')">
                      <i class="el-icon-plus" style="font-size: 30px;"></i>
                      <span></span>
                    </div>
                  </div>
                  <input type="hidden" v-model="formValidate.mainImage" placeholder="请添加封面">
                </el-form-item>
                <el-form-item>
                  <i class="el-icon-delete" style="cursor:pointer" @click="formValidate.mainImage=''"></i>
                </el-form-item>
              </el-form>
              <!-- 剪裁组件弹窗 -->
              <el-dialog title="裁切封面" :visible.sync="cropperModel" width="auto" center>
                <cropper-image
                :myOption="option"
                :Name="cropperName" @uploadImgSuccess="handleUploadSuccess" ref="cropperChild">
                </cropper-image>
              </el-dialog>
              <!--查看大封面-->
              <!-- <el-dialog
                 title="查看大封面"
                 :visible.sync="imgVisible"
                 center>
               <img :src="imgName" v-if="imgVisible" style="width: 100%" alt="查看">
             </el-dialog> -->
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="labelIds" class="form_label">
        <el-row>
          <el-col :md="{span:1}">
            <span style="font-size: 20px;"> <strong>标签</strong></span>
          </el-col>
          <el-col :md="{span:23}">
            <el-select multiple collapse-tags v-model="form.labelIds" placeholder="请选择标签" style="width:100%;">
              <div class="el-input" style="width:100%;">
                <input v-model="dropDownValue" placeholder="请输入" class="el-input__inner"
                  style="border: none;outline: none;" @keyup="dropDownSearch" />
              </div>
              <el-option style="display: none;" key="none" value="none" label="暂无数据">
              </el-option>

              <el-option v-for="labelItem in labelList" :key="labelItem.id" :label="labelItem.labelName"
                :value="labelItem.id"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-row :gutter="20">
          <el-col :md="{span:8}">
            <el-button type="primary" @click="onSubmit" style="width: 100%;">
              发布
            </el-button>
          </el-col>
          <el-col :md="{span:8}">
            <el-button type="primary" @click="updateBlog" style="width: 100%;">
              保存
            </el-button>
          </el-col>
          <el-col :md="{span:8}">
            <el-button type="primary" @click="saveDrafts()" style="width: 100%;">
              保存为新的草稿
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <!-- 剪裁组件弹窗 -->

  </div>
</template>
<script>
  import {
    publishArticleApi,
    getAllLabelApi,
    getDetailArticelByIdApi,
    updateArticleApi
  } from '@/api/article'
  import {uploadFileApi,uploadImageApi} from '@/api/upload'
  import 'mavon-editor/dist/markdown/github-markdown.min.css'
  import global from '@/api/global_variable.js'
  import hljs from 'highlight.js'
  import VueCropper from 'vue-cropper'
  import ScreenShot from '@/components/ScreenShotDemo.vue'
  import CropperImage from "@/components/CropperImage.vue"
import { mapGetters } from 'vuex'
  // import
  export default {
    computed: {
      ...mapGetters([
        'fileUploadProgress'
      ])
    },
    components: {
      ScreenShot,
      CropperImage
    },
    created() {
      if(this.$route.query.blogId == undefined){
        let currForm=localStorage.getItem("currentBlogCache");
        if(currForm!=undefined && currForm!=null){
          this.form=JSON.parse(currForm);
          this.form.id=undefined
          this.blogItem.id=undefined
          this.blogContent=this.form.blogMd 
        }
      }
      this.getAllLabel()
      if (this.$route.query.blogId != undefined) {
        this.getDetailArticelById()
      }
      this.codeStyleList = this.codeStyleListAll
      this.form.codeStyle = 'agate'
    },
    data() {
      return {
        progressColors:[
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#409eff', percentage: 60},
          {color: '#5cb87a', percentage: 80},
          {color: '#67c23a', percentage: 100}
        ],
        loadingObj:{},
        cacheFileList:[],
        loading: false,
        blogContent: '',
        codeStyleValue: '',
        codeStyleListAll: [{
          style: 'agate',
          styleName: ''
        }, {
          style: 'androidstudio',
          styleName: ''
        }, {
          style: 'arduino-light',
          styleName: ''
        }, {
          style: 'arta',
          styleName: ''
        }, {
          style: 'ascetic',
          styleName: ''
        }, {
          style: 'atom-one-dark',
          styleName: ''
        }, {
          style: 'atom-one-light',
          styleName: ''
        }, {
          style: 'brown-paper',
          styleName: ''
        }, {
          style: 'codepen-embed',
          styleName: ''
        }, {
          style: 'color-brewer',
          styleName: ''
        }, {
          style: 'dark',
          styleName: ''
        }, {
          style: 'default',
          styleName: ''
        }, {
          style: 'docco',
          styleName: ''
        }, {
          style: 'far',
          styleName: ''
        }, {
          style: 'foundation',
          styleName: ''
        }, {
          style: 'github',
          styleName: ''
        }, {
          style: 'googlecode',
          styleName: ''
        }, {
          style: 'grayscale',
          styleName: ''
        }, {
          style: 'hybrid',
          styleName: ''
        }, {
          style: 'idea',
          styleName: ''
        }, {
          style: 'ir-black',
          styleName: ''
        }, {
          style: 'magula',
          styleName: ''
        }, {
          style: 'mono-blue',
          styleName: ''
        }, {
          style: 'monokai-sublime',
          styleName: ''
        }, {
          style: 'monokai',
          styleName: ''
        }, {
          style: 'obsidian',
          styleName: ''
        }, {
          style: 'paraiso-dark',
          styleName: ''
        }, {
          style: 'paraiso-light',
          styleName: ''
        }, {
          style: 'pojoaque',
          styleName: ''
        }, {
          style: 'purebasic',
          styleName: ''
        }, {
          style: 'rainbow',
          styleName: ''
        }, {
          style: 'routeros',
          styleName: ''
        }, {
          style: 'school-book',
          styleName: ''
        }, {
          style: 'sunburst',
          styleName: ''
        }, {
          style: 'tomorrow-night-blue',
          styleName: ''
        }, {
          style: 'tomorrow-night-bright',
          styleName: ''
        }, {
          style: 'vs',
          styleName: ''
        }, {
          style: 'vs2015',
          styleName: ''
        }, {
          style: 'xcode',
          styleName: ''
        }, {
          style: 'xt256',
          styleName: ''
        }, {
          style: 'a11y-dark',
          styleName: ''
        }, {
          style: 'a11y-light',
          styleName: ''
        }, {
          style: 'an-old-hope',
          styleName: ''
        }, {
          style: 'atom-one-dark-reasonable',
          styleName: ''
        }, {
          style: 'brown-pap',
          styleName: ''
        }, {
          style: 'devibeans',
          styleName: ''
        }, {
          style: 'github-dark',
          styleName: ''
        }, {
          style: 'github-dark-dimmed',
          styleName: ''
        }, {
          style: 'gml',
          styleName: ''
        }, {
          style: 'gradient-dark',
          styleName: ''
        }, {
          style: 'gradient-light',
          styleName: ''
        }, {
          style: 'isbl-editor-dark',
          styleName: ''
        }, {
          style: 'isbl-editor-light',
          styleName: ''
        }, {
          style: 'kimbie-dark',
          styleName: ''
        }, {
          style: 'kimbie-light',
          styleName: ''
        }, {
          style: 'lightfair',
          styleName: ''
        }, {
          style: 'lioshi',
          styleName: ''
        }, {
          style: 'night-owl',
          styleName: ''
        }, {
          style: 'nnfx-dark',
          styleName: ''
        }, {
          style: 'nnfx-light',
          styleName: ''
        }, {
          style: 'nord',
          styleName: ''
        }, {
          style: 'pojo',
          styleName: ''
        }, {
          style: 'qtcreator-dark',
          styleName: ''
        }, {
          style: 'qtcreator-light',
          styleName: ''
        }, {
          style: 'shades-of-purple',
          styleName: ''
        }, {
          style: 'srcery',
          styleName: ''
        }, {
          style: 'stackoverflow-dark',
          styleName: ''
        }, {
          style: 'stackoverflow-light',
          styleName: ''
        }, ],
        codeStyleList: [],
        //裁切图片参数
        formValidate: {
          mainImage: '',
        },
        ruleValidate: {
          mainImage: [{
            required: true,
            message: '请上传封面',
            trigger: 'blur'
          }],
        },
        //裁切图片参数
        cropperModel: false,
        cropperName: '',
        imgName: '',
        imgVisible: false,
        bigPicUrl: 'http://qianyafan.top:8080/images/blog-logo.png',
        centerDialogVisible: true,
        imgURL: global.imgURL,
        dropDownValue: '',
        dropDownCodeStyle: '',
        content: '',
        externalLink: {
          markdown_css: function() {
            // 这是你的markdown css文件路径
            return '/mavon-editor/markdown/github-markdown.min.css'
          },
          hljs_js: function() {
            // 这是你的hljs文件路径
            return '/mavon-editor/highlightjs/highlight.min.js'
          },
          hljs_css: function(css) {
            // 这是你的代码高亮配色文件路径
            return '/mavon-editor/highlightjs/styles/' + css + '.min.css'
          },
          hljs_lang: function(lang) {
            // 这是你的代码高亮语言解析路径
            return '/mavon-editor/highlightjs/languages/' + lang + '.min.js'
          },
          katex_css: function() {
            // 这是你的katex配色方案路径路径
            return '/mavon-editor/katex/katex.min.css'
          },
          katex_js: function() {
            // 这是你的katex.js路径
            return '/mavon-editor/katex/katex.min.js'
          }
        },
        form: {
          id: '',
          heading: '',
          typeId: '',
          blog: '',
          labelIds: [],
          isDrafts: 0,
          codeStyle: '',
        },
        rules: {
          heading: [{
            required: true,
            message: '请输入文章标题',
            trigger: 'blur'
          }, {
            min: 3,
            message: '文章标题不能少于3个字符',
            trigger: 'blur'
          }],
          typeId: [{
            required: true,
            message: '请选择文章分类',
            trigger: 'change'
          }],
          blog: [{
            required: true,
            message: '请输入文章内容',
            trigger: 'blur'
          }],
          labelIds: [{
            required: true,
            message: '请选择文章标签'
          }]

        },
        option:{
          img: '',             //裁剪图片的地址
          outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
          outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
          info: true,          //图片大小信息
          canScale: true,      //图片是否允许滚轮缩放
          autoCrop: true,      //是否默认生成截图框
          autoCropWidth: 230,  //默认生成截图框宽度
          autoCropHeight: 230, //默认生成截图框高度
          fixed: false,         //是否开启截图框宽高固定比例
          fixedNumber: [1, 1], //截图框的宽高比例
          full: true,         //按原比例裁切图片，不失真
          fixedBox: false,      //固定截图框大小，不允许改变
          canMove: true,      //上传图片是否可以移动
          canMoveBox: true,    //截图框能否拖动
          original: true,     //上传图片按照原始比例渲染
          centerBox: true,    //截图框是否被限制在图片里面
          height: false,        //是否按照设备的dpr 输出等比例图片
          infoTrue: true,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
          maxImgSize: 3000,    //限制图片最大宽度和高度
          enlarge: 1,          //图片根据截图框输出比例倍数
        },
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          strikethrough: true, // 中划线
          mark: true, // 标记
          superscript: true, // 上角标
          subscript: true, // 下角标
          quote: true, // 引用
          ol: true, // 有序列表
          ul: true, // 无序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          table: true, // 表格
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          htmlcode: true, // 展示html源码
          redo: true, // 下一步
          alignleft: true, // 左对齐
          aligncenter: true, // 居中
          alignright: true, // 右对齐
          /* 1.3.5 */
          undo: true, // 上一步
          trash: true, // 清空
          save: true, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: true // 导航目录
        },
        codeStyle: 'monokai-sublime',
        labelList: [],
        labelListAll: [],
        preImgUrlList: [],
        blogItem: {},
        timer:'',
        }
    },
    mounted() {
      this.timer = setInterval(this.autoSave, 1000*60*2);
    },
    methods: {
      copy(text){
        this.$copyText(text).then((e) => {
          this.$message({
            type:'success',
            message:'复制成功'
          })
        }, (e) => {

        })
      },
      async uploadFile(file,fileList,loading){
        this.$set(this.loadingObj,loading,true)
        var newdate = new FormData()
        newdate.append("file", file.raw)
        await uploadFileApi(newdate).then((res) => { //图片上传到服务器返回图片url 链接
          if(res.data.success){
            this.blogContent+='['+file.name+']('+res.data.data+')'
            file.url=res.data.data
            this.cacheFileList.push({
              fileName:file.name,
              filePath:res.data.data
            })
            this.$notify({
              type:'success',
              message:'附件'+file.name+'上传成功'
            })
          }else{
            this.$notify({
              type:'success',
              message:res.data.errorMsg
            })
          }
          this.$set(this.loadingObj,loading,false)
          this.$store.dispatch('message/fileUPloadProgressRest')

        });

      },
      clickTest() {

      },
      synopsisChange() {
        this.$forceUpdate()
      },
      previewCurrentImg(preImgUrl) {
        this.preImgUrlList = []
        this.preImgUrlList.push(preImgUrl)
      },
      //封面设置
      uploadPicture(name) {
        // this.$refs.cropperChild.option.fixedBox=true//固定截图框大小
        this.cropperName = name;
        this.cropperModel = true;
      },
      //图片上传成功后
      handleUploadSuccess(data) {
        switch (data.name) {
          case 'flagImg':
            this.formValidate.mainImage = data.url;
            this.form.backgroundImg = data.url
            break;
        }
        this.cropperModel = false;
      },
      imgDel() {

      },
      handleEditorImgAdd(pos, file) {
        var newdate = new FormData();
        newdate.append("file", file);
        uploadImageApi(newdate).then((res) => { //图片上传到服务器返回图片url 链接
          this.$refs.mavonEditor.$img2Url(pos,res.data.data); //通过ref插入到文档中
        });
      },
      dropDownSearch() {
        var _this = this;
        _this.labelList = _this.labelListAll.filter(item => item.labelName.includes(_this.dropDownValue));
      },
      codeStyleSearch() {
        var _this = this;
        _this.codeStyleList = _this.codeStyleListAll.filter(item => item.style.includes(_this.codeStyleValue));
      },
      async getAllLabel() {
        const res = await getAllLabelApi()
        this.labelList = res.data.data
        this.labelListAll = this.labelList
      },
      async getDetailArticelById() {
        const res = await getDetailArticelByIdApi({
          blogId: this.$route.query.blogId
        })
        this.blogItem = res.data.data
        if (this.blogItem.heading != null) {
          this.form.heading = this.blogItem.heading
        }
        if (this.blogItem.codeStyle != null) {
          this.form.codeStyle = this.blogItem.codeStyle
        }
        if (this.blogItem.blogMd != null) {
          this.blogContent = this.blogItem.blogMd
        }
        if (this.blogItem.labelList != null) {
          let labelIds = []
          this.blogItem.labelList.forEach(function(value, key, arr) {
            labelIds.push(value.id)
          })
          this.form.labelIds = labelIds
        }
        if (this.blogItem.synopsis != null) {
          this.form.synopsis = this.blogItem.synopsis
        }
        if (this.blogItem.backgroundImg != null) {
          this.form.backgroundImg = this.blogItem.backgroundImg
          this.formValidate.mainImage = this.blogItem.backgroundImg
        }

      },
      change() {
        this.form.blog = this.$refs.mavonEditor.d_render
        this.form.blogMd = this.$refs.mavonEditor.d_value
      },
      onSubmit() {
        this.loading = true
        this.$refs.form.validate(async valid => {
          if (valid) {
            const res = await publishArticleApi(this.form)
            if (res.data.success === true) {
              this.$message({
                type: 'success',
                message: '发布成功'
              })
              this.loading = false
              this.$router.push('/blog')
            } else {
              this.$message({
                type: 'error',
                message: res.data.errorMsg
              })
              this.loading = false
            }
            
          } else {
            this.loading = false
            this.$message({
              type: 'error',
              message: '发布失败'
            })
          }
        })
      },
      saveDrafts() {
        this.loading = true
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.form.isDrafts = 1
            const res = await publishArticleApi(this.form)
            if (res.data.success === true) {
              this.loading = false
              this.blogItem.id=res.data.data.blogId
              this.$message({
                type: 'success',
                message: '保存草稿成功'
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          } else {
            this.loading = false
            this.$message({
              type: 'error',
              message: '保存失败'
            })
          }
        })
      },
      autoSave() {
        if (this.blogItem.id != undefined) {
          this.updateBlog()
        }
      },
      updateBlog() {
        if (this.blogItem.id != undefined) {
          this.loading = true
          this.$refs.form.validate(async valid => {
            if (valid) {
              this.form.id = this.blogItem.id
              const res = await updateArticleApi(this.form)
              if (res.data.success === true) {
                this.loading = false
                this.$message({
                  type: 'success',
                  message: '保存成功'
                })
                // this.$router.push('/article')
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            } else {
              return false
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先发布或保存为新的草稿'
          })
        }
      }
    },
    beforeDestroy() {
      if(this.blogItem.id == undefined){
        localStorage.setItem("currentBlogCache",JSON.stringify(this.form));
      }
      clearInterval(this.timer);
    }
  }
</script>
<style lang="less" scoped>
  .upload-list-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    transition: opacity 1s;
  }

  .cover_icon {
    font-size: 30px;
  }

  .upload-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 1px #cccccc;
  }

  .upload-btn:hover {
    border: 1px solid #69b7ed;
  }

  .upload-btn i {
    margin: 5px;
  }

  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #C0C4CC;
  }

  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #C0C4CC;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #C0C4CC;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #C0C4CC;
  }

  /deep/ .el-select,
  /deep/ .el-input,
  /deep/ .el-input__inner {
    border: none;
  }

  .form_label {
    border: 1px solid #E5E5E5;
    padding-left: 10px;
    padding-right: 20px;
  }

  .data_label {}

  .publish {
    height: 100%;
    padding: 15px;
  }

  .el-form {
    margin: 50px 0;
  }

  /deep/ .el-textarea__inner {
    border: 0px;
    resize: none;
  }

  .el-input,
  .el-select,
  .quill-editor {
    width: 800px;
  }

  /deep/.ql-container {
    height: 300px;
  }

  /deep/.ql-stroke {
    stroke: black;
  }

  /deep/.ql-fill {
    fill: black;
  }

  /deep/ .ql-picker-label {
    color: black;
  }

  .btn {
    width: 800px;
    margin-top: 20px;
  }

  pre.code {
    position: relative;
    border-radius: 3px;
    border: 1px solid #C3CCD0;
    overflow: hidden;
    padding-left: 60px !important;

    code {
      line-height: 30px !important;
    }

    ol.pre-numbering {
      position: absolute;
      top: 0;
      left: 5px;
      line-height: 30px;
      padding: 16px 0;
      list-style-type: none;
      counter-reset: sectioncounter;
      margin-bottom: 0;

      li {
        margin-top: 0 !important;

        &:before {
          content: counter(sectioncounter) "";
          counter-increment: sectioncounter;
          display: inline-block;
          width: 40px;
          text-align: center;
          border-right: solid 1px rgba(0, 0, 0, 0.53);
        }
      }
    }

    i.code-copy {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #464d5e;
      padding: 3px;
      margin: 3px 3px 0 0;
      font-size: 11px;
      border-radius: inherit;
      color: #f1f1f1;
      cursor: pointer;
      display: none;
    }

    &:hover i.code-copy {
      display: block;
    }
  }
</style>
