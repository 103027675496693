<template>
  <div id="app">
    <div class="model" v-show="model" @click="model = false">
      <div class="model-show">
        <img :src="modelSrc" alt="">
      </div>
    </div>
    <p style="color: #000;">截图demo</p>
    <div class="cut">
      <vue-cropper
        ref="cropper"
        :img="option.img"
        :output-size="option.size"
        :output-type="option.outputType"
        :info="true"
        :full="option.full"
        :fixed="fixed"
        :fixed-number="fixedNumber"
        :can-move="option.canMove"
        :can-move-box="option.canMoveBox"
        :fixed-box="option.fixedBox"
        :original="option.original"
        :auto-crop="option.autoCrop"
        :auto-crop-width="option.autoCropWidth"
        :auto-crop-height="option.autoCropHeight"
        :center-box="option.centerBox"
        @real-time="realTime"
        :high="option.high"
        @img-load="imgLoad"
        mode="cover">
      </vue-cropper>
      <div class="show-preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
        <div :style="previews.div">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
    </div>
    <div class="test-button">
      <button @click="changeImg" class="btn">changeImg</button>
      <label class="btn" for="uploads">upload</label>
      <input
        type="file"
        id="uploads"
        style="position:absolute; clip:rect(0 0 0 0);"
        accept="image/png, image/jpeg, image/gif, image/jpg"
        @change="uploadImg($event, 1)"
      >
      <button @click="startCrop" v-if="!crap" class="btn">开始</button>
      <button @click="stopCrop" v-else class="btn">结束</button>
      <button @click="clearCrop" class="btn">清空</button>
      <button @click="refreshCrop" class="btn">刷新</button>
      <button @click="changeScale(1)" class="btn">放大</button>
      <button @click="changeScale(-1)" class="btn">缩小</button>
      <button @click="rotateLeft" class="btn">向右旋转90</button>
      <button @click="rotateRight" class="btn">向左旋转90</button>
      <button @click="finish('base64')" class="btn">预览(base64)</button>
      <button @click="finish('blob')" class="btn">预览(blob)</button>
      <a @click="down('base64')" class="btn">下载(base64)</a>
      <a @click="down('blob')" class="btn">下载(blob)</a>
      <div style="display:block; width: 100%;color: #000;">
        <label class="c-item">
          <span>上传图片是否显示原始宽高 (针对大图 可以铺满)</span>
          <input type="checkbox" v-model="option.original">
          <span>original: {{ option.original}}</span>
        </label>
        <label class="c-item">
          <span>能否拖动图片</span>
          <input type="checkbox" v-model="option.canMove">
        </label>
        <label class="c-item">
          <span>能否拖动截图框</span>
          <input type="checkbox" v-model="option.canMoveBox">
          <span>canMoveBox: {{ option.canMoveBox}}</span>
        </label>
        <label class="c-item">
          <span>截图框固定大小</span>
          <input type="checkbox" v-model="option.fixedBox">
          <span>fixedBox: {{ option.fixedBox}}</span>
        </label>
        <label class="c-item">
          <span>是否输出原图比例的截图</span>
          <input type="checkbox" v-model="option.full">
          <span>full: {{ option.full}}</span>
        </label>
        <label class="c-item">
          <span>是否自动生成截图框</span>
          <input type="checkbox" v-model="option.autoCrop">
          <span>autoCrop: {{ option.autoCrop}}</span>
        </label>
        <label class="c-item">
          <span>是否根据dpr生成适合屏幕的高清图片</span>
          <input type="checkbox" v-model="option.high">
          <span>high: {{ option.high}}</span>
        </label>
        <label class="c-item">
          <span>截图框是否限制在图片里(只有在自动生成截图框时才能生效)</span>
          <input type="checkbox" v-model="option.centerBox">
          <span>centerBox: {{ option.centerBox}}</span>
        </label>
        <label class="c-item">
          <p>输出图片格式</p>
          <label>jpg
            <input type="radio" name="type" value="jpeg" v-model="option.outputType">
          </label>
          <label>png
            <input type="radio" name="type" value="png" v-model="option.outputType">
          </label>
          <label>webp
            <input type="radio" name="type" value="webp" v-model="option.outputType">
          </label>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
export default {
  name: 'ScreenShotDemo',
  components: {
    VueCropper
  },
  data() {
    return {
      model: false,
      modelSrc: '',
      crap: false,
      previews: {},
      lists: [
        {
          img: 'https://qn-qn-kibey-static-cdn.app-echo.com/goodboy-weixin.PNG'
        },
        {
          img: 'https://avatars2.githubusercontent.com/u/15681693?s=460&v=4'
        }
      ],
      option: {
        // 裁剪图片的地址
        img: 'https://qn-qn-kibey-static-cdn.app-echo.com/goodboy-weixin.PNG',
        // 裁剪生成图片的质量
        size: 1,
        // 输出原图比例截图 props名full
        full: false,
        // 裁剪生成图片的格式
        outputType: 'png',
        // 上传图片是否可以移动
        canMove: true,
        // 固定截图框大小 不允许改变
        fixedBox: false,
        // 上传图片按照原始比例渲染
        original: false,
        // 截图框能否拖动
        canMoveBox: true,
        // 是否默认生成截图框
        autoCrop: false,
        // 只有自动截图开启 宽度高度才生效
        // 默认生成截图框宽度
        autoCropWidth: 200,
        // 默认生成截图框高度
        autoCropHeight: 150,
        // 截图框是否被限制在图片里面
        centerBox: false,
        // 是否按照设备的dpr 输出等比例图片
        high: true
      },
      show: true,
      // 是否开启截图框宽高固定比例
      fixed: false,
      // 截图框的宽高比例
      fixedNumber: [1, 2],
      // 裁剪框的大小信息
      info: true,
      // canScale 图片是否允许滚轮缩放
      canScale: true,
      // infoTrue  true 为展示真实输出图片宽高 false 展示看到的截图框宽高:
      infoTrue: true,
      // maxImgSize  限制图片最大宽度和高度
      maxImgSize: 2000,
      // enlarge 图片根据截图框输出比例倍数
      enlarge: 1,
      // mode  图片默认渲染方式
      mode: 'contain'
    }
  },
  methods: {
    /**
     * 内置方法 通过this.$refs.cropper 调用
     this.$refs.cropper.startCrop() 开始截图
     this.$refs.cropper.stopCrop() 停止截图
     this.$refs.cropper.clearCrop() 清除截图
     this.$refs.cropper.changeScale() 修改图片大小 正数为变大 负数变小
     this.$refs.cropper.getImgAxis() 获取图片基于容器的坐标点
     this.$refs.cropper.getCropAxis() 获取截图框基于容器的坐标点
     this.$refs.cropper.goAutoCrop 自动生成截图框函数
     this.$refs.cropper.rotateRight() 向右边旋转90度
     this.$refs.cropper.rotateLeft() 向左边旋转90度
     图片加载的回调 imgLoad 返回结果success, error
     获取截图信息
     this.$refs.cropper.cropW 截图框宽度
     this.$refs.cropper.cropH 截图框高度
     */
    changeImg() {
      this.option.img = this.lists[~~(Math.random() * this.lists.length)].img
    },
    startCrop() {
      // start
      this.crap = true
      this.$refs.cropper.startCrop()
    },
    stopCrop() {
      //  stop
      this.crap = false
      this.$refs.cropper.stopCrop()
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop()
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh()
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      // 输出
      // var test = window.open('about:blank')
      // test.document.body.innerHTML = '图片生成中..'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          console.log(data)
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data
      this.$emit('', this.previews)
      console.log(data)
    },

    finish2(type) {
      this.$refs.cropper2.getCropData((data) => {
        this.model = true
        this.modelSrc = data
      })
    },
    finish3(type) {
      this.$refs.cropper3.getCropData((data) => {
        this.model = true
        this.modelSrc = data
      })
    },
    down(type) {
      // event.preventDefault()
      var aLink = document.createElement('a')
      aLink.download = 'demo'
      // 输出
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          this.downImg = window.URL.createObjectURL(data)
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.downImg = data
          aLink.href = data
          aLink.click()
        })
      }
    },
    uploadImg(e, num) {
      // 上传图片
      // this.option.img
      var file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
        return false
      }
      var reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        if (num === 1) {
          this.option.img = data
        } else if (num === 2) {
          this.example2.img = data
        }
      }
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file)
    },
    imgLoad(msg) {
      console.log(msg)
    }
  }
}
</script>
<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .cut {
    width: 500px;
    height: 500px;
    margin: 30px auto;
  }
  .c-item {
    max-width: 800px;
    margin: 10px auto;
    margin-top: 20px;
  }
  .content {
    margin: auto;
    max-width: 1200px;
    margin-bottom: 100px;
  }
  .test-button {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c0ccda;
    color: #1f2d3d;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin:20px 10px 0px 0px;
    padding: 9px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #50bfff;
    border-color: #50bfff;
    transition: all .2s ease;
    text-decoration: none;
    user-select: none;
  }
  .des {
    line-height: 30px;
  }
  code.language-html {
    padding: 10px 20px;
    margin: 10px 0px;
    display: block;
    background-color: #333;
    color: #fff;
    overflow-x: auto;
    font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
    border-radius: 5px;
    white-space: pre;
  }
  .show-info {
    margin-bottom: 50px;
  }
  .show-info h2 {
    line-height: 50px;
  }
  .title {
    display: block;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    margin: 20px 0px;
    background-image: -webkit-linear-gradient(left,#3498db,#f47920 10%,#d71345 20%,#f7acbc 30%,#ffd400 40%,#3498db 50%,#f47920 60%,#d71345 70%,#f7acbc 80%,#ffd400 90%,#3498db);
    color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: slide 5s infinite linear;
    font-size: 40px;
  }
  .test {
    height: 500px;
  }
  .model {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .model-show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .model img {
    display: block;
    margin: auto;
    max-width: 80%;
    user-select: none;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
  }
  .c-item {
    display: block;
    user-select: none;
  }
  @keyframes slide {
    0%  {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
</style>